import React from "react";

import { Col, Row } from "react-bootstrap";

import "../styles/pages/_unavailable.sass";

import banner from "../images/503.png";

import { Logo } from "../components/Icons";
import { FormattedMessage } from "react-intl";

export const Unavailable = () => {
  return (
    <div className="unavailable-page">
      <Row>
        <Col className="left-col" xs={12} lg={6}>
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="content">
                <div className="logo">
                  <Logo />
                </div>
                <h1>
                  <FormattedMessage id="503.title" />
                </h1>
                <div className="border"></div>
                <p>
                  <FormattedMessage id="503.desc.1" />
                </p>
                <p>
                  <FormattedMessage id="503.desc.2" />
                  <a href="mailto:support@duqqy.com">
                    support@duqqy.com
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="right-col" xs={12} lg={6}>
          <Row className="align-items-center justify-content-center">
            <Col xs="auto">
              <div className="content">
                <img src={banner} alt="" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
