import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { IntlProvider } from "react-intl";

import "../styles/index.sass";

import enMessages from "../intl/en";
import ruMessages from "../intl/ru";
import esMessages from "../intl/es";
import ptMessages from "../intl/pt";

function getLocaleMessages(locale) {
  let messages = enMessages;

  switch (locale) {
    case "ru":
      messages = ruMessages;
      break;
    case "es":
      messages = esMessages;
      break;
    case "pt":
      messages = ptMessages;
      break;
    default:
      messages = enMessages;
  }

  return messages;
}

const getCookie = (name) => {
  if (typeof window === "undefined") return null;

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const Layout503 = ({ children, location, locale = "ru" }) => {
  const currentLocale = getCookie("user_lang") || locale;

  const messages = getLocaleMessages(currentLocale);

  return (
    <>
      <IntlProvider
        defaultLocale="en"
        locale={currentLocale}
        messages={messages}
      >
        {children}
      </IntlProvider>
    </>
  );
};

export default Layout503;
